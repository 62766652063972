<template>
  <div>获取临时访问链接</div>
</template>

<script>
export default {

}
</script>

<style>

</style>